/* General Styles */
:root {
  --header-height: 90px;
  --footer-height: 86px;
}

body {
  color: #1d1d1d;
  height: 100vh;
  overflow-x: visible !important;
  font-family: Inter, Open Sans, Microsoft JhengHei, Tahoma, Helvetica,
    Microsoft Yahei, Arial, sans-serif;
}

#page-wrap {
  margin-top: var(--header-height);
}

/* CountDownPageContents.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  width: 50px;
  height: 50px;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.App {
  font-size: 1.8rem;
}

.flex-column-container {
  display: flex;
  flex-direction: column;
}

/* Flash Message */
.App .flash-message {
  position: absolute;
  min-width: 320px;
  top: 170px;
  z-index: 1005;
  left: 50%;
  transform: translateX(-50%);
}

/* Header */
.Header {
  position: fixed;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: var(--header-height);
  padding-left: 40px;
  z-index: 1101;
  width: 100%;
  background: #fff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
}

.logo-container {
  position: absolute;
  height: 72px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: fit-content;
}

.Header .logo {
  height: 100%;
}

.Header-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  gap: 16px;
}

.hamburger-menu,
.hamburger-menu-close {
  margin-left: auto;
  width: 24px;
  height: 24px;
  background-size: cover;
}

.hamburger-menu {
  background-image: url("./assets/icons/menu.svg");
  order: 2;
}

.hamburger-menu-close {
  background-image: url("./assets/icons/close.svg");
  order: 2;
}

.Header-link {
  height: 100%;
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  position: relative;
  font-size: 16px;
  padding-right: 15px;
  white-space: nowrap;
  border-radius: 25px; /* To match the rounded rectangle shape */
}

.Header-link.active,
.Header-link:hover {
  text-decoration: none;
  color: #0a7deb;
  background-color: #e4f1ff;
}

/* Additional class for background color */
.Header-link.active::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #e4f1ff; /* Background color to match the image */
  border-radius: 25px; /* Match the rounded corners */
  z-index: -1; /* Ensure the background is behind the text */
}

/* Title Bar */

.TitleBar-container {
  height: 65px;
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.TitleBar {
  display: flex;
  flex-direction: column;
  height: 232px;
  padding: 24px 40px;
  width: 100%;
  gap: 20px;
  background: #08194c;
}

.TitleBar-title {
  margin-left: 35px;
  position: relative;
  display: flex;
  align-items: center;
  color: var(--white, #fff);
  font-size: 36px;
  font-weight: 800;
}

/* Key Visual */
.KeyVisual {
  display: flex;
  padding: 40px 155px 400px 155px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-position: center;
  height: 540px;
}

/* Advertisement */
.Advertisement {
  margin: 0 auto;
  max-width: 980px;
  width: 100%;
  height: 100px;
  position: relative;
}

.Advertisement .adv-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Home Page */
.Page.Page-Home .Content {
  padding: 0;
}

.Page.Page-Home .Content .container {
  padding-left: 0;
  padding-right: 0;
  max-width: none;
}

.Rows {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.featured-video-item {
  border-radius: 5px;
  background: var(--white, #fff);
  box-shadow: 0px 15px 40px 0px rgba(10, 125, 235, 0.3);
}

/* Section Titles */
.section-title {
  position: relative;
  color: var(--black, #1d1d1d);
  font-family: "Open Sans";
  font-size: 36px;
  font-weight: 800;
  padding-left: 30px;
}

.orange-rectangle {
  width: 54px;
  height: 60px;
  flex-shrink: 0;
  position: absolute;
}

.video-sections-container {
  padding-top: 30px;
}

.section-container {
  padding: 80px 0;
}

.button {
  border-radius: 50px;
  border: 1px solid var(--viu-main, #0a7deb);
  background: var(--viu-main, #0a7deb);
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.show-more-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Rows Background Color */
.Rows {
  background-color: #f4f5f8;
}

.row-container {
  width: 100%;
}

/* Video Item */
.video-item-text-container {
  display: flex;
  padding: 12px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
  border-radius: 0px 0px 5px 5px;
  background: var(--BG-1, #fff);
}

.featured-video-item-text-container {
  display: flex;
  padding: 40px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 5px;
  background: var(--white, #fff);
}

.card-text {
  color: var(--black, #1d1d1d);
  font-size: 16px;
  font-weight: 700;
}

.featured-video-item-text-container .card-text {
  font-size: 28px;
}

.video-item-img-container,
.featured-video-item-img-container {
  width: 100%;
  padding-top: calc(100% / 1.76);
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.featured-video-item-img-container {
  padding-top: calc(100% / 1.7778);
}

.video-item-img-container img,
.featured-video-item-img-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Footer */
.Footer {
  position: relative;
  bottom: 0;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  height: var(--footer-height);
  padding: 32px 40px;
  width: 100%;
  gap: 10px;
  background: #fff;
}

.Footer .copyright-container .copyright,
.Footer .right-nav-container {
  color: var(--white, #1d1d1d);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
}

.copyright-container {
  flex: 1;
}

.right-nav-container {
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 40px;
}

.right-nav-container a {
  text-decoration: none;
  color: #1d1d1d;
}

.right-nav-container a:hover {
  text-decoration: none;
}

.right-nav-container a:visited {
  text-decoration: none;
}

/* Mobile Menu Page */
.MobileMenuPage {
  width: 100%;
  height: calc(100vh - var(--footer-height));
  background: #08194c;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 16px;
  gap: 40px; /* Adjust the gap as needed */
}

.mobile-menu-link {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  text-decoration: none;
  font-weight: 700;
}

/* Schedule Filter */
.schedule-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 5px;
  flex-wrap: wrap;
}

.schedule-filter-items-btns {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1; /* Distribute the remaining space evenly */
  min-width: 0; /* Prevent items from overflowing */
}

.schedule-filter-items-btn {
  padding: 10px;
  border-radius: 50px;
  border: 1px solid var(--viu-main, #0a7deb);
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
}

.schedule-filter-items-btn:hover {
  color: #fff;
}

.schedule-filter-items-btn.active {
  color: #fff;
}

.filter-button {
  display: none;
  cursor: pointer;
}

.schedule-row {
  display: flex;
  align-items: center;
}

.schedule-list {
  min-height: calc(
    100vh - var(--header-height) - var(--footer-height) - var(--header-height)
  );
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.schedule-time {
  font-size: 28px;
  font-weight: bold;
  color: #1d1d1d;
  width: 76px; /* Fixed width for time */
  text-align: center; /* Center align time */
}

.schedule-group {
  display: flex;
  flex-direction: column;
}

.schedule-group-header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0 10px; /* Add padding for spacing */
  gap: 60px;
}

.schedule-group-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px; /* Space between items */
}

.schedule-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 16px;
  background: #fff;
  border: 1px solid rgba(29, 29, 29, 0.1);
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
}

.schedule-content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  font-weight: 700;
  min-width: 0; /* Ensure flex items don't shrink below their content */
  flex-grow: 1;
}

.schedule-right-div {
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: fit-content;
}

.schedule-channel {
  color: var(--black, #1d1d1d);
  font-size: 14px;
  font-weight: 500;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 2px;
  flex-shrink: 0; /* Prevent shrinking */
}

.schedule-description {
  font-size: 20px;
  font-weight: 700;
  color: #333;
}

.schedule-teams {
  font-size: 14px;
  color: #888;
  font-style: normal;
}

/* Filter Overlay */
.filter-overlay {
  position: fixed;
  bottom: -100%; /* Start off-screen */
  left: 0;
  width: 100%;
  height: calc(100vh - var(--header-height));
  border-radius: 5px 5px 0px 0px;
  background: var(--white, #fff);
  transition: bottom 0.3s ease-in-out;
  z-index: 9900;
  padding: 20px 16px;
  visibility: hidden;
  flex-direction: column;
  align-items: flex-end;
}

.filter-overlay.show {
  visibility: visible;
  bottom: 0; /* Slide up to visible position */
}

.filter-overlay-header {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: var(--black, #1d1d1d);
  /* button */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter-overlay-header img {
  cursor: pointer;
}

.filter-overlay-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--black, #1d1d1d);
  /* button */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter-overlay-item {
  padding: 10px;
  cursor: pointer;
  text-align: center;
  border-radius: 50px;
  border: 1px solid rgba(29, 29, 29, 0.2);
}

.filter-overlay-item.selected {
  background-color: #0a7deb; /* Blue background for selected items */
  color: #fff;
}

.clear-filters {
  width: 100%;
  padding: 10px;
  background-color: #888;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  bottom: 0;
}

/* Filter List */
.filter-list-overlay {
  position: fixed;
  bottom: -100%; /* Start off-screen */
  left: 0;
  width: 100%;
  min-height: calc(100vh - var(--header-height));
  background: white;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
  transition: bottom 0.3s ease-in-out;
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 90%; /* Ensure there's some space at the top */
  color: var(--black, #1d1d1d);
  /* button */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter-list-overlay.show {
  bottom: 0; /* Slide up to visible position */
}

.filter-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-list-header img {
  cursor: pointer;
}

.filter-list-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-list-item {
  padding: 14px 30px;
  background: #ffff;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.filter-list-item.selected {
  color: #fff;
  background: var(--viu-main, #0a7deb);
}

.tick-icon {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  fill: #fff; /* White color for the tick icon */
}

.selected-tags-container {
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  flex: 1 1 100%; /* Take full width of the container */
}

.tag {
  background-color: #0a7deb;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.remove-tag {
  margin-left: 5px;
  cursor: pointer;
}


/* Dim Overlay */
.dim-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: var(--black, #1d1d1d);
  z-index: 1200; /* Place it above other elements but below the filter overlay */
}

/* Live Indicator */
.live-indicator {
  background: var(--live, #ee334e);
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px 16px;
  cursor: pointer;
  flex-shrink: 0; /* Prevent shrinking */
}

.live-indicator span {
  color: var(--grey, #f4f5f8);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.live-icon {
  width: 24px;
  height: 24px;
}

.live-streaming {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  min-width: fit-content;
}

.live-streaming span {
  color: var(--live, #ee334e);
  font-size: 16px;
  font-weight: 700;
}

.schedule-external-link {
  background: #ee334e;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  flex-shrink: 0; /* Prevent shrinking */
}

.schedule-divider {
  border: 0;
  border-top: 1px solid #1D1D1D;
  opacity: 0.1;
  margin: 24px 0; /* Space above and below the divider */
}

.schedule-page-container {
  min-height: calc(
    100vh - var(--header-height) - var(--footer-height) - var(--header-height)
  );
  padding: 0 10px; /* Add padding for spacing */
}

/* Custom Dropdown */
.custom-dropdown {
  position: relative;
  margin-right: 10px;
  display: inline-block;
  padding: 12px;
  border-radius: 50px;
  border: 1px solid rgba(29, 29, 29, 0.2);
  background: var(--white, #fff);
  cursor: pointer;
}

.custom-dropdown-button {
  display: flex;
  align-items: center;
  gap: 5px; /* Adjust the gap between text and icon */
  color: var(--black, #1d1d1d);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-dropdown-icon {
  width: 16px; /* Adjust size as needed */
  height: 16px; /* Adjust size as needed */
}

.custom-dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  overflow-y: auto;
  border: 1px solid var(--viu-main, #0a7deb);
  margin-top: 17px;
}

.custom-dropdown-item {
  width: 177px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1d1d1d;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 10px;
  border-bottom: 1px solid #ccc; /* Add border between items */
}

.custom-dropdown-item:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.custom-dropdown-item.selected {
  background-color: #0a7deb; /* Blue background for selected items */
  color: #fff;
  font-weight: 700;
}

.custom-dropdown-item:hover {
  background-color: #0a7deb; /* Blue background for selected items */
  color: #fff;
  font-weight: 700;
}

.tick-icon {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  fill: #fff; /* White color for the tick icon */
}

/* Toggle Buttons */
.toggle-buttons {
  overflow: hidden;
  border-radius: 50px;
  border: 1px solid rgba(29, 29, 29, 0.2);
  background: var(--white, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-button {
  padding: 13px 19px;
  cursor: pointer;
  background: #fff;
  color: var(--black, #1d1d1d);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.toggle-button.active {
  background: #0a7deb;
  color: var(--white, #fff);
  border-radius: 50px;
  z-index: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Horizontal Date Picker */
.horizontal-date-picker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.scroll-button {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--viu-main, #0a7deb);
}

.scroll-button.left {
  position: absolute;
  left: 35px;
}

.scroll-button.right {
  position: absolute;
  right: 35px;
}

.horizontal-date-picker {
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  border-radius: 5px;
  gap: 20px;
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.horizontal-date-picker::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
}

.date-list {
  display: flex;
}

.date-item {
  padding: 15px 18px;
  background: none;
  color: #000;
  cursor: pointer;
  text-align: center;
  border-radius: 10px;
}

.date-item.selected {
  background-color: #0a7deb;
  color: #fff;
}

.month-day {
  font-size: 16px;
  font-weight: 700;
}

.week-day {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
}

.date-item.selected .week-day {
  opacity: 1;
}

/* Video Card */
.video-card {
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 10px;
}

.card-text {
  color: var(--black, #1d1d1d);
  font-size: 16px;
  font-weight: 700;
}

.video-duration {
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 4px;
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px 0px;
  background: rgba(29, 29, 29, 0.6);
}

.video-duration .video-duration-num {
  color: var(--white, #fff);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

/* Rectangles */
.rectangle-container {
  display: flex;
  align-items: center;
  height: 100%; /* Make the container take up 100% of the header’s height */
  margin-left: auto; /* Pushes it to the right side */
}

.rectangle {
  width: 12px; /* Adjust as needed */
  height: 100%; /* Make each rectangle take up 100% of the header’s height */
}

.rectangle-1 {
  background-color: #08194c;
} /* Navy */
.rectangle-2 {
  background-color: #f3970d;
} /* Orange */
.rectangle-3 {
  background-color: #0168b7;
} /* Blue */
.rectangle-4 {
  background-color: #ed344e;
} /* Red */
.rectangle-5 {
  background-color: #019e95;
} /* Teal */

/* Media Queries */
/* Ipad View */
@media (max-width: 992px) {
  .scroll-button {
    display: none;
  }

  .filter-button {
    display: flex;
    height: 44px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 50px;
    border: 1px solid rgba(29, 29, 29, 0.2);
    background: var(--white, #fff);
  }
  .filter-button span {
    color: var(--black, #1d1d1d);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .schedule-filter-items-btns {
    display: none;
  }
  .custom-dropdown {
    display: none;
  }
}

/* Small Screens */
@media (max-width: 768px) {
  :root {
    --header-height: 80px;
    --footer-height: 97px;
  }

  .Header {
    padding: 0 16px;
  }

  .logo-container {
    height: 60px;
  }


  .Header .logo-desc {
    height: 4px;
    width: 41px;
  }

  .TitleBar {
    padding: 0 16px;
  }

  .TitleBar-title {
    padding: 0;
  }

  .TitleBar {
    height: 212px;
  }

  .featured-video-item-text-container {
    padding: 16px;
  }

  .video-item {
    display: flex;
    align-items: center;
  }

  .video-item-img-container {
    flex-shrink: 0;
    width: 50%;
    padding-top: calc(50% / 1.76);
  }

  .video-item-text-container {
    width: 100%;
    padding: 8px 16px;
    gap: 4px;
  }

  .video-item-text {
    font-size: 14px;
  }

  .video-item-duration {
    font-size: 12px;
  }

  .Footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
  }

  .Footer .copyright-container,
  .Footer .right-nav-container {
    width: 100%;
  }

  .Footer .right-nav-container {
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  .right-append {
    align-self: flex-end;
  }
}

@media (max-width: 569px) {
  .Page[style*="transform: translate3d(240px, 0px, 0px)"] {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .schedule-group {
    width: 100%;
  }

  .schedule-group-header {
    display: block;
    padding: 0px;
  }

  .schedule-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 20px;
    gap: 0px;
    background: #fff;
    border: 1px solid rgba(29, 29, 29, 0.1);
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .schedule-description {
    font-size: 16px;
  }

  .schedule-filter {
    padding: 20px 0px;
  }

  .schedule-list {
    padding: 8px 0;
  }

  .schedule-time {
    padding-bottom: 15px;
  }

  .schedule-content {
    width: 100%;
    gap: 42px;
  }

  .schedule-right-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding-top: 8px;
  }
}

@media (min-width: 992px) {
  .horizontal-date-picker {
    width: 75%;
  }
  .schedule-group-column {
    width: 566px;
  }
}

/* Large Screens */
@media (min-width: 1200px) {
  .horizontal-date-picker {
    width: 900px;
  }

  .container.section-container {
    max-width: 1170px;
  }

  .schedule-group-column {
    width: 766px;
  }

  .container {
    max-width: 1170px !important;
  }
}


