// olp = overlay player
.olp {
  &.olp__wrapper {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: #1D1D1D;
    display: flex;
    flex-direction: column;

    .olp__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;

      .logo-container {
        .logo {
          width: 150px;
          height: 75px
        }
      }

      .olp__btn {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--viu-main, #0A7DEB);        
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        margin-left: auto;
      }
    }

    .olp__body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      gap: 20px;

      .olp__top-ad {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }

      .Advertisement {
        align-self: center;
        max-width: 980px;
        width: 70vw;
        height: 100px;
        position: relative;
        overflow: hidden;

        .adv-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .olp__video-info {
        color: #ffffff;
        margin-bottom: 0 auto;
        width: 100%;
        max-width: 980px;
        font-size: 14px;

   
      }

      .olp__video-title {
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .olp__event-date {
        opacity: 0.5;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .olp__teams {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .olp__container {
        width: 100%;
        max-width: 980px;
        margin: 0 auto;
        aspect-ratio: 16 / 9;
        background-color: #1D1D1D;
      }
    }
  }
}
